import advantages1 from '../../assets/images/advantages1.svg'
import advantages2 from '../../assets/images/advantages2.svg'
import advantages3 from '../../assets/images/advantages3.svg'
import advantages4 from '../../assets/images/advantages4.svg'
import { SectionItem } from './sectionItem'
import React from 'react';
import { BlurElipse6 } from '../utils/blurElipse'

export const SecondSection = () => {

    return (

        <section id='secondSection' className='container position-relative'>
            <BlurElipse6 />
            <h1 className="intro-text-color text-center">
                Výhody řešení
            </h1>
            <div className="row mx-auto d-flex align-items-start  p-0 py-0 py-md-5">
                <SectionItem
                    imgSrc={advantages1}
                    headline={'Automatizace'}
                    text={'Díky aplikaci se automaticky hlídají zásoby a dostupnost zboží s možností notifikací. Máte okamžitý přehled o odběrech a prodejích. Díky kreditům nabitým na uživatelském účtu je lednice ideálním řešením pro rychlý odběr na firemních akcích, možnost platby kartou pak umožňuje využití na trzích a jiných veřejných místech.'}
                    extraMargin={true} isMultiline={false} imgAlt={'výhoda chytré lednice - automatizace'} isLast={false} />
                <SectionItem
                    imgSrc={advantages2}
                    headline={'Řízený odběr a zabezpečení'}
                    text={'Zabezpečený přístup pomocí elektronického zámku s rychlou a intuitivní identifikací a ověřením uživatele.'}
                    extraMargin={false} isMultiline={true} imgAlt={'výhoda chytré lednice - řízený přístup a zabezpečení'} isLast={false} />
            </div>
            <div className="row mx-auto d-flex align-items-start  p-0 py-0 py-md-5">
                <SectionItem
                    imgSrc={advantages3}
                    headline={'Chytré firemní benefity'}
                    text={'Zpříjemněte den svým zaměstnancům chlazenými nápoji nebo občerstvením s možností nastavení ceny či výše slevy. V kancelářích, výrobních halách, nemocnicích nebo hotelech můžete odměnit své zaměstnance čerstvými produkty a zvýšit jejich výkon a spokojenost.'}
                    extraMargin={false} isMultiline={false} imgAlt={'výhoda chytré lednice - chytré firemní benefity'} isLast={false} />
                <SectionItem
                    imgSrc={advantages4}
                    headline={`Finance`}
                    text={'Zákazníci ocení rychlý nákup pomocí přednabitých kreditů nebo online platby. Majitelé lednic pak přehledy prodejů a jiné užitečné reporty. '}
                    extraMargin={false} isMultiline={false} imgAlt={'výhoda chytré lednice - rychlý nákup a finance'}
                    isLast={true} />
            </div>

            <hr className='hr-gradient-light' />
        </section >
    )
}