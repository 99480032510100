import team from '../assets/images/team.svg'
import bottomBg from '../assets/images/bottom_bg.svg'
import topBg from '../assets/images/top_bg.svg'
import React from 'react';
import { BlurElipse2 } from './utils/blurElipse';


export const FirstSection = () => {

    return (
        <>
            <img src={topBg} alt="pozadí" className='w-100' />
            <section id="firstSection" className='light-background position-relative pt-1' >
                <BlurElipse2 />
                <div className="container row mx-auto d-flex align-items-center  p-0 py-1">
                    <div data-aos="fade-right" className="col-12 col-lg-5 p-0 mx-auto text-center text-md-start">
                        <img className='w-75' src={team} alt="ilustrace" />

                    </div>
                    <div className="col-12 col-lg-7 text-center text-md-start">
                        <div>
                            <h2 data-aos="fade-up" className='bold-text increase-headline-size dark-color'>
                                Benefit pro zaměstnance
                            </h2>
                            <p data-aos="fade-up" className='grey-color pe-0 pe-lg-5  mt-4'>
                                Nabídněte svým zaměstnancům chlazené nápoje, svačiny, ovoce nebo jiné produkty z lednice, které jim zpříjemní pracovní prostředí. Zaměstnavateli naopak ulehčí práci on-line přehled skladových zásob a odběrů. 
                            </p>
                            <hr className='hr-gradient' />
                            <h2 data-aos="fade-up" className='bold-text increase-headline-size mt-4 dark-color'>
                                Automatizovaný výdej/prodej produktů 24/7
                            </h2>
                            <p data-aos="fade-up" className='grey-color pe-0 pe-lg-5 mt-4'>
                                Umožněte řízený výdej nebo prodej produktů kdykoli a kdekoli, ať jste farmář, lokální výrobce potravin nebo firma.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <img src={bottomBg} className='w-100' alt="pozadí" />
        </>
    )
}