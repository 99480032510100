import map from '../../assets/images/map.png'
import envelope from '../../assets/images/envelope.svg'
import { Form } from './form'
import React from 'react';
import { BlurElipse3, BlurElipse4 } from '../utils/blurElipse';


export const FifthSection = () => {
    return (
        <section className='mb-5 container'>
            <div id="fifthSection" className="row mt-0 mt-md-5 pt-5 d-flex align-items-center justify-content-around position-relative">
                <BlurElipse4 />
                <BlurElipse3 />
                {/* <a href='https://goo.gl/maps/2sr3DHeoDGdW7X4o8' target="_blank" data-aos="fade-right" className="col-12 col-lg-6 d-flex justify-content-center">
                    <img src={map} className="w-75 map" alt="kde nás najdete - mapa" />
                </a> */}
                {/* only for desktop view */}
                {/* <div data-aos="fade-up" className=" d-none d-lg-block col-12 col-lg-4 flex-column text-left">
                    <h1 className='text-white mb-2 mb-lg-5 mt-5 mt-lg-0'> Naše kontakty</h1>
                    <p className='text-white opacity-75 '><i className="fa fa-phone fa-2x opacity-100 icon-color align-middle pe-2"></i> +420 605 387 212</p>
                    <p className='text-white opacity-75 py-0 py-md-3'><i className="fa fa-envelope fa-2x opacity-100 icon-color align-middle pe-2"></i>jan.chocholac@devcity.tech</p>
                    <p className='text-white opacity-75 '><i className="fa fa-map fa-2x opacity-100 icon-color align-middle pe-2"></i>Dělnická 776/5 Praha 7 Holešovice 170 00</p>
                </div> */}
                {/* only for mobiles */}
                {/* <div data-aos="fade-up" className="d-block d-lg-none col-12 col-lg-4 flex-column text-left w-75">
                    <h1 className='text-white mb-2 mb-lg-5 mt-5 mt-lg-0'> Naše kontakty</h1>
                    <p className='text-white opacity-75 '><i className="fa fa-phone fa-2x opacity-100 icon-color align-middle pe-2"></i> +420 605 387 212</p>
                    <p className='text-white opacity-75 py-0 py-md-3'><i className="fa fa-envelope fa-2x opacity-100 icon-color align-middle pe-2"></i>jan.chocholac@devcity.tech</p>
                    <p className='text-white opacity-75 '><i className="fa fa-map fa-2x opacity-100 icon-color align-middle pe-2"></i>Dělnická 776/5 Praha 7 Holešovice 170 00</p>
                </div> */}
                <a href='https://goo.gl/maps/2sr3DHeoDGdW7X4o8' target="_blank" data-aos="fade-right" className="col-12 col-lg-6 d-flex justify-content-center">
                    <img src={map} className="w-75 map" alt="kde nás najdete - mapa" />
                </a>
                <div data-aos="fade-up" className="col-12 col-lg-6 flex-column text-center">
                    <h1 className='text-white mb-2 mb-lg-5 mt-5 mt-lg-0'> Naše kontakty</h1>
                    <p className='text-white opacity-75 '><i className="fa fa-phone fa-2x opacity-100 icon-color align-middle pe-2"></i> +420 605 387 212</p>
                    <p className='text-white opacity-75 py-0 py-md-3'><i className="fa fa-envelope fa-2x opacity-100 icon-color align-middle pe-2"></i>jan.chocholac@devcity.tech</p>
                    <p className='text-white opacity-75 '><i className="fa fa-map fa-2x opacity-100 icon-color align-middle pe-2"></i>Dělnická 776/5 Praha 7 Holešovice 170 00</p>
                </div>

            </div>
            <div className="row">
                <div className="col-12 col-lg-6"></div>
                <div className="col-12 col-lg-6">
                    <hr className='hr-gradient-light mx-5' />
                </div>
            </div>
            <div id="contactForm" className="row mt-0 mt-md-5 pt-5 d-flex align-items-center">
                <div data-aos="fade-right" data-aos-delay="300" className="col-12 col-lg-6 d-flex justify-content-center">
                    <Form />
                </div>
                <div data-aos="fade-up" data-aos-delay="300" className="col-12 col-lg-6 flex-column text-center mt-5 mt-lg-0">
                    <h1 className='text-white mb-5'> Máte ještě <br /> nějaké otázky?</h1>
                    <p className='text-white opacity-75 '>Vyplňte krátký formulář pro <br /> zpětnou vazbu a my se vám <br />ozveme.</p>
                    <img src={envelope} className="w-50" alt="ilustrace - dotazy" />
                </div>
            </div>

        </section>
    )
}