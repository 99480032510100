import { CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';

export const Form = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isSent, setIsSent] = useState<undefined | boolean>(undefined)

    const [name, setName] = useState('')
    const [nameValid, setNameValid] = useState<string>('')

    const [message, setMessage] = useState('')
    const [messageValid, setMessageValid] = useState<string>('')

    const [phone, setPhone] = useState('')
    const [phoneValid, setPhoneValid] = useState<string>('')

    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState<string>('')


    const isPhoneValid = (p: string) => {
        var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
        var digits = p.replace(/\D/g, "");
        return phoneRe.test(digits);
    }

    const isEmailValid = (p: string) => {
        var emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRe.test(p);
    }

    const sendEmail = () => {
        if (name.length < 1) {
            setNameValid('Prosím zadejte své jméno a příjmení')
        }
        if (phone.length > 0 && !isPhoneValid(phone)) {
            setPhoneValid('Prosím zadejte telefon ve správném formátu')
        }
        if (!isEmailValid(email)) {
            setEmailValid('Prosím zadejte email ve správném formátu')
        }
        if (message.length < 1) {
            setMessageValid('Prosím vyplně váš dotaz')
        }
        if (name.length > 1 && message.length > 1 && isPhoneValid(phone) && isEmailValid(email)) {
            setIsLoading(true)
            setIsSent(undefined)
            axios({
	            method: 'post',
	            url: `https://api.pickit.cz/contact/messages`,
	            headers: {
		            'Content-Type': 'application/json',
	            },
	            data: {
								fullName: name,
		            phone,
		            email,
		            message,
	            }
            })
                .then(function (response) {
                    setIsLoading(false)
                    setIsSent(true)
                    reset(true)
                })
                .catch(function (error) {
                    console.log('Error happened while sending message', error);
                    setTimeout(() => {
                        setIsLoading(false)
                        setIsSent(false)
                    }, 3000);
                    reset(false)

                });
        }

    }

    const reset = (wasSuccessful: boolean) => {
        setTimeout(() => {
            setIsLoading(false)
            setIsSent(undefined)
            if (wasSuccessful) {
                setName('')
                setPhone('')
                setMessage('')
                setEmail('')
            }

        }, 8000);
    }



    return (
        <section className="light-background col-10 col-md-9 custom-form text-center flex-column py-1 py-md-4">
            <h2 className="mt-3">Kontaktujte nás</h2>
            <TextField error={nameValid.length > 0} helperText={nameValid} label="Jméno*" variant="outlined" value={name} onChange={e => { setNameValid(''), setName(e.target.value) }} className="custom-form col-11 col-md-9 mx-3" />
            <TextField error={phoneValid.length > 0} helperText={phoneValid} label="Telefon" variant="outlined" value={phone} onChange={e => { setPhoneValid(''), setPhone(e.target.value) }} className="custom-form col-11 col-md-9 mx-3 my-3" />
            <TextField error={emailValid.length > 0} helperText={emailValid} label="Vaše e-mailová adresa*" variant="outlined" value={email} onChange={e => { setEmailValid(''), setEmail(e.target.value) }} type="email" className="custom-form col-11 col-md-9 mx-3" />
            <TextField multiline rows={4} error={messageValid.length > 0} helperText={messageValid} label="Dotaz*" variant="outlined" value={message} onChange={e => { setMessageValid(''), setMessage(e.target.value) }} className="custom-form col-11 col-md-9 mx-3 my-3" />
            <LoadingButton
                size="small"
                onClick={() => sendEmail()}
                loading={isLoading}
                color={isLoading ? "primary" : isSent === undefined ? "primary" : isSent === true ? "success" : "error"}
                loadingIndicator={<CircularProgress color="primary" size={26} thickness={5} />}
                variant="contained"
                className='order-button custom-button  text-white py-3 px-5 w-75 w-md-50 mb-3'
            >
                {isLoading ? <div style={{ color: 'rgba(0, 0, 0, 0.12)' }}>&zwnj;  </div> :
                    isSent === undefined ? "Odeslat" : isSent === true ? "Odesláno" : "Nepodařilo se odeslat"}
            </LoadingButton>
        </section>
    )
}
