import { SectionItem } from "./sectionItem"
import howItWorks1 from "../../assets/images/howItWorks1.svg"
import howItWorks2 from "../../assets/images/howItWorks2.svg"
import howItWorks3 from "../../assets/images/howItWorks3.svg"
import React from 'react';
import { BlurElipse5 } from "../utils/blurElipse";


export const ThirdSection = () => {

    return (
        <section id="thirdSection" className='mt-5 container pt-5 mb-5 pb-5 position-relative'>
            <div className="mb-xxl-5 pb-5">
                <BlurElipse5 />
                <h1 className="intro-text-color text-center">
                    Jak chytrá lednice funguje
                </h1>
                <div className="row mx-4 d-flex align-items-start  p-0 py-5 my-0 my-xl-5">
                    <SectionItem
                        number={"1"}
                        imgSrc={howItWorks1}
                        headline={"Výběr"}
                        delay={0}
                        text={"Po zaplacení nebo stržení kreditů se lednice otevře"}
                        imgAlt={"jak to funguje - výber"} />
                    <SectionItem
                        number={"2"}
                        imgSrc={howItWorks2}
                        headline={"Platba"}
                        delay={200}
                        text={"Online kartou nebo pomocí kreditů"}
                        imgAlt={"jak to funguje - platba"} />
                    <SectionItem
                        number={"3"}
                        imgSrc={howItWorks3}
                        headline={"Odběr"}
                        delay={400}
                        text={"Po zaplacení nebo stržení kreditů se lednice otevřete"}
                        imgAlt={"jak to funguje - odběr"} />
                </div>
            </div>
        </section>
    )
}